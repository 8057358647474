const validate = {
	email: (value) => {
		return value === ''
			? 'Email nécessaire'
			: !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
			? 'Adresse email invalide'
			: null;
	},
};

export default validate;
