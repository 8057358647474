import React from 'react';
import { Box, CloseButton, Container, Modal, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import {
  COLOR_PRIMARY,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
} from '/config/theme';

const CustomModal = ({
  modalOpened,
  setModalOpened,
  onClose,
  background,
  fontColor,
  children,
}) => {
  const isMobile = useMediaQuery('(max-width: 800px)');

  const closeHandler = () => {
    setModalOpened(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      opened={modalOpened}
      withCloseButton={false}
      onClose={closeHandler}
      fullScreen
      sx={{
        width: '100%',
      }}
      padding={0}
      styles={{
        modal: {
          background,
        },
      }}
      transition='fade'
      transitionDuration={200}
    >
      <>
        <Stack
          align='flex-end'
          sx={{
            position: 'fixed',
            width: '100%',
            top: 0,
            paddingBottom: SPACING_SM,
          }}
        >
          <CloseButton
            onClick={closeHandler}
            size='xl'
            radius={0}
            sx={{
              outline: 'none',
              background: '#fff',
              '&:hover': {
                background: '#fff',
              },
            }}
          />
        </Stack>

        <Container>{children}</Container>

        <Container mt={SPACING_MD} mb={SPACING_LG}>
          <a
            onClick={closeHandler}
            style={{
              color: fontColor ? fontColor : COLOR_PRIMARY,
              fontWeight: 'bold',
              cursor: 'pointer',
              marginLeft: isMobile ? 16 : 0,
            }}
          >
            Retour
          </a>
        </Container>
      </>
    </Modal>
  );
};

export default CustomModal;
