import React from 'react';
import {
	Box,
	Button,
	Group,
	Select,
	Stack,
	Text,
	Textarea,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

import useCaptcha from '/hooks/useCaptcha';

import validate from '../../lib/validate';
import {
	COLOR_PRIMARY,
	COLOR_PRIMARY_LIGHT,
	FONT_COLOR_GREY,
	SPACING_LG,
	SPACING_MD,
} from '../../config/theme';

const WorkshopSubscriptionForm = ({ data, isMobile }) => {
	const form = useForm({
		initialValues: {
			email: '',
			lastname: '',
			firstname: '',
			phone: '',
			message: '',
			hcaptcha: null,
		},

		validate: {
			email: validate.email,
			lastname: (value) => (value ? null : 'Nom nécessaire'),
			firstname: (value) => (value ? null : 'Prénom nécessaire'),
			phone: (value) => (value ? null : 'Téléphone nécessaire'),
		},
	});

	const [Captcha, captcha_ref] = useCaptcha();

	const submitHandler = async (formValues) => {
		const showErrorNotification = (status) => {
			console.log(status);

			let message;

			switch (status) {
				case 498:
					message = 'Captcha invalide';
					break;
				case 409:
					message = "Votre demande d'inscription nous est déjà parvenue";
					break;
			}

			showNotification({
				id: 'server-status',
				title: 'Erreur',
				message: message
					? message
					: `Une erreur est survenue ${status ? status : ''}`,
				color: 'red',
				icon: <IconX />,
				autoClose: 4000,
			});
		};

		const { email, lastname, firstname, phone, message, hcaptcha } = formValues;

		try {
			const res = await fetch('/api/workshops/subscribe', {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					email,
					lastname,
					firstname,
					phone,
					message,
					workshop_id: data._id,
					hcaptcha,
				}),
			});

			if (res.status >= 200 && res.status < 300) {
				showNotification({
					id: 'server-status',
					title: 'Succès',
					message:
						"Votre demande d'inscription nous est parvenue ! Un email vous a été envoyé pour confirmer votre demande",
					color: 'lime',
					icon: <IconCheck />,
					autoClose: 8000,
				});
			} else {
				showErrorNotification(res.status);
			}

			form.reset();
			captcha_ref.current.resetCaptcha();
		} catch (err) {
			showErrorNotification();
		}
	};

	return (
		<form
			onSubmit={form.onSubmit((values) => {
				submitHandler(values);
			})}
			style={{ marginTop: SPACING_LG }}
		>
			<Stack
				grow={1}
				align='flex-start'
				spacing={SPACING_LG}
				sx={{ flexDirection: isMobile ? 'column' : 'row' }}
			>
				<Stack spacing={SPACING_MD} sx={{ width: '100%' }}>
					<TextInput
						placeholder='Adresse email'
						variant='unstyled'
						{...form.getInputProps('email')}
					/>
					<TextInput
						placeholder='Nom'
						variant='unstyled'
						{...form.getInputProps('lastname')}
					/>
					<TextInput
						placeholder='Prénom'
						variant='unstyled'
						{...form.getInputProps('firstname')}
					/>
					<TextInput
						placeholder='Téléphone'
						variant='unstyled'
						{...form.getInputProps('phone')}
					/>
				</Stack>
				<Stack sx={{ height: '100%', width: '100%' }} spacing={SPACING_MD}>
					<Textarea
						variant='unstyled'
						placeholder='Message (optionnel)'
						{...form.getInputProps('message')}
						sx={{ height: 140 }}
					/>
					<Captcha
						{...form.getInputProps('hcaptcha')}
						// onChange={(val) => {
						// 	console.log(val);
						// }}
					/>
				</Stack>
			</Stack>
			<Button
				type='submit'
				mt={SPACING_LG}
				sx={{
					background: COLOR_PRIMARY,
					color: '#fff',
					'&:hover': {
						background: COLOR_PRIMARY_LIGHT,
					},
				}}
			>
				S'inscrire
			</Button>
		</form>
	);
};

export default WorkshopSubscriptionForm;
