import React, { Fragment } from 'react';
import { Text } from 'slate';
import escapeHTML from 'escape-html';
import Image from 'next/image';
import { Anchor, Text as MantineText } from '@mantine/core';
import getCdnUrl from '@/utility/getCdnUrl';
import Link from 'next/link';
import { COLOR_PRIMARY } from '@/config/theme';

const CustomLink = ({ href, target, style, children }) => {
  return (
    <Link href={href} target={target}>
      <Anchor sx={style}>{children}</Anchor>
    </Link>
  );
};

const serialize = ({ data_arr, paragraphSpacing, indentAtParagraphStart }) =>
  data_arr.map((node, i) => {
    if (Text.isText(node)) {
      let nodeText = node.text;

      let escapedText_elements = nodeText;

      if (nodeText.includes('\n')) {
        const escapedText_arr = nodeText.split('\n');

        const lastTextSegment = escapedText_arr.pop();

        escapedText_elements = (
          <>
            {escapedText_arr.map((text, i) => (
              <Fragment key={text + i}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: escapeHTML(text)
                      .replace('« ', '«&nbsp;')
                      .replace(' »', '&nbsp;»'),
                  }}
                />
                <br />
              </Fragment>
            ))}
            {lastTextSegment}
          </>
        );
      }

      let text = escapedText_elements;

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      // Handle other leaf types here...

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return (
          <h1 key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </h1>
        );
      // Iterate through all headings here...
      case 'h6':
        return (
          <h6 key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </h6>
        );
      case 'quote':
        return (
          <blockquote key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </blockquote>
        );
      case 'ul':
        return (
          <ul key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </ul>
        );
      case 'ol':
        return (
          <ol key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </ol>
        );
      case 'li':
        return (
          <li key={i}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </li>
        );
      case 'link':
        return (
          <CustomLink
            key={i}
            href={escapeHTML(node.url)}
            target={node.newTab ? '_blank' : '_self'}
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              '&:hover': { textDecoration: 'underline', color: COLOR_PRIMARY },
            }}
          >
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </CustomLink>
        );
      case 'indent':
        return (
          <div key={i} style={{ paddingLeft: 25 }}>
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </div>
        );
      case 'upload':
        if (node?.value?.url) {
          const image_url = getCdnUrl(
            node.value.sizes.normal.filesize !== null
              ? node.value.sizes.normal.url
              : node.value.url
          );
          return (
            <Image
              key={i}
              src={image_url}
              sizes='80vw'
              height={node.value.height}
              width={node.value.width}
              placeholder='blur'
              blurDataURL={node.value.sizes.thumbnail.url}
              loader={() => {
                return image_url;
              }}
              style={{ width: '100%', height: 'auto' }}
            />
          );
        } else {
          return null;
        }

      default:
        return (
          <MantineText
            component='p'
            key={i}
            sx={{
              marginTop: 0,
              // marginTop: paragraphSpacing ? 16 : 0,
              marginBottom: 0,

              '&:not(:first-of-type)': {
                marginTop: paragraphSpacing ? 16 : 0,
              },
            }}
          >
            {indentAtParagraphStart && (
              <span style={{ width: 24, display: 'inline-block' }} />
            )}
            {serialize({
              data_arr: node.children,
              paragraphSpacing,
              indentAtParagraphStart,
            })}
          </MantineText>
        );
    }
  });

const RichText = ({ data, paragraphSpacing, indentAtParagraphStart }) => {
  if (Array.isArray(data)) {
    return (
      <>
        {serialize({
          data_arr: data,
          paragraphSpacing,
          indentAtParagraphStart,
        })}
      </>
    );
  } else {
    return null;
  }
};

export default RichText;
