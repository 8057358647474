const { DateTime } = require('luxon');

export const displayTextDate = (date, show_day) => {
	const date_iso = DateTime.fromISO(date);
	const date_str = date_iso
		.toLocaleString(show_day ? DateTime.DATE_HUGE : DateTime.DATE_FULL)
		.replace(',', '');

	return (
		date_str.charAt(0).toUpperCase() + date_str.substring(1, date_str.length)
	);
};
