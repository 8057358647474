import React, { useState } from 'react';
import { Box, Collapse, Group, Stack, Table, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import Plume from '../_generic/Plume';
import WorkshopDetails from './WorkshopDetails';
import PageButton from '../_generic/PageButton';
import {
	COLOR_PRIMARY,
	FONT_COLOR_GREY,
	SPACING_LG,
	SPACING_MD,
	SPACING_SM,
	SPACING_XS,
} from '../../config/theme';

import useWorkshopSessions from '../../hooks/useWorkshopSessions';
import WorkshopSubscriptionForm from './WorkshopSubscriptionForm';
import { DateTime } from 'luxon';
import RichText from '../_generic/RichText';

const WorkshopModalContent = ({ data }) => {
	const [formOpened, setFormOpened] = useState(false);

	const workshopSessions = useWorkshopSessions(data);

	const when_date = DateTime.fromISO(data.when_date);
	const when_str = when_date
		.toLocaleString(DateTime.DATE_HUGE)
		.replace(',', '');

	const isMobile = useMediaQuery('(max-width: 800px)');

	const selectedWorkshop_is_passed = data?.when_date < new Date().toISOString();

	const duration_minutes = data.duration_minutes % 60;
	const duration_minutes_str = duration_minutes.toString();

	const is_subscriptions_opened = data.subscriptions_auto_close
		? data.subscriptions_auto_close &&
		  !(data.subscriptions_confirmed_count >= data.participants_max)
		: data.subscriptions_opened;

	const remaining_places =
		data.participants_max - data.subscriptions_confirmed_count;

	return (
		<>
			<Box mt={SPACING_LG}>
				<Plume opacity={selectedWorkshop_is_passed ? 0.15 : 1} />
			</Box>
			<Title
				order={1}
				mt={SPACING_XS}
				sx={{
					textAlign: 'center',
					fontSize: 32,
				}}
			>
				{data?.title}
			</Title>
			<Box
				mt={SPACING_MD}
				sx={{ background: '#fff' }}
				p={isMobile ? SPACING_SM : SPACING_LG}
			>
				<Stack sx={{ flexDirection: 'row' }}>
					<Box sx={{ flex: 1 }}>
						{/* <WorkshopDetails data={data} /> */}
						<Text
							sx={{
								marginTop: SPACING_MD,
								fontSize: 18,
								lineHeight: 1.25,
							}}
						>
							{data.where}
							{data?.where_details && ` (${data.where_details})`}
							<br />
							{when_str.charAt(0).toUpperCase()}
							{when_str.substring(1, when_str.length)}
						</Text>

						<Text
							sx={{
								marginTop: SPACING_MD,
								fontSize: 18,
								lineHeight: 1.25,
							}}
						>
							Durée: {Math.round(data.duration_minutes / 60)}h
							{duration_minutes !== 0 && (
								<>
									{duration_minutes_str.length === 1 ? '0' : ''}
									{duration_minutes}
								</>
							)}
							<br />
							Prix: CHF {data.price_chf}.-
						</Text>
					</Box>

					<Box>
						<Table mt={SPACING_MD} sx={{ width: 'inherit' }}>
							<thead>
								<tr>
									<th>Horaire{workshopSessions?.length > 1 && 's'}</th>
								</tr>
							</thead>
							<tbody>
								{workshopSessions?.map((session, i) => (
									<tr key={session}>
										<td>{session}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Box>
				</Stack>

				<Box
					mt={SPACING_MD}
					mb={SPACING_MD}
					sx={{ fontSize: 18, lineHeight: 1.25, color: COLOR_PRIMARY }}
				>
					{data.description_richtext && (
						<RichText data={data.description_richtext} />
					)}
				</Box>

				{/* <Text
					sx={{
						marginTop: SPACING_MD,
						marginBottom: SPACING_MD,
						fontSize: 18,
						lineHeight: 1.25,
						color: COLOR_PRIMARY,
					}}
				>
					<div dangerouslySetInnerHTML={{ __html: data?.description }} />
				</Text> */}
				{!selectedWorkshop_is_passed && (
					<Group align='center'>
						<PageButton
							title={formOpened ? 'Masquer le formulaire' : 'Inscription'}
							disabled={!is_subscriptions_opened}
							onClick={() => {
								setFormOpened((value) => !value);
							}}
							sx={{ marginTop: 0 }}
						/>
						{is_subscriptions_opened && (
							<Text sx={{ color: FONT_COLOR_GREY }}>
								{data.display_remaining_places && data.participants_max > 0
									? `(${remaining_places} place${
											remaining_places > 1 ? 's' : ''
									  } restante${remaining_places > 1 ? 's' : ''})`
									: '(dans la limite des places disponibles)'}
							</Text>
						)}
					</Group>
				)}
				<Collapse in={formOpened}>
					<WorkshopSubscriptionForm
						data={data}
						workshopSessions={workshopSessions}
						isMobile={isMobile}
					/>
				</Collapse>
			</Box>
		</>
	);
};

export default WorkshopModalContent;
