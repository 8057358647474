import { DateTime } from 'luxon';

const useWorkshopSessions = (data) => {
	if (data?.sessions) {
		const when_date = DateTime.fromISO(data.when_date);
		const when_str = when_date.toLocaleString(DateTime.DATE_HUGE);

		return data.sessions.map((session) => {
			const { start_time } = session;

			const start_datetime = DateTime.fromISO(start_time);
			const end_time = start_datetime.plus({
				minutes: data.duration_minutes / data.sessions.length,
			});

			const start_time_str = start_datetime.toFormat('HH:mm');
			const end_time_str = end_time.toFormat('HH:mm');

			return `${start_time_str} - ${end_time_str}`;
		});
	}

	return null;
};

export default useWorkshopSessions;
