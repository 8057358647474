import React, { useCallback, useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { HCAPTCHA_SITE_KEY } from '@/config/env';

const useCaptcha = () => {
	const hCaptcha_ref = useRef(null);

	const Captcha = useCallback(
		(props) => (
			<div style={{ height: 75 }}>
				<HCaptcha
					ref={hCaptcha_ref}
					sitekey={HCAPTCHA_SITE_KEY}
					onVerify={(token) => {
						props?.onChange(token);
					}}
				/>
			</div>
		),
		[]
	);

	return [Captcha, hCaptcha_ref];
};

export default useCaptcha;
