import Image from 'next/image';

import { Center } from '@mantine/core';

const Plume = ({ isMobile, opacity }) => {
	return (
		<Center>
			<Image
				style={{ opacity: opacity ? opacity : 1 }}
				src={`${'/images/plume.svg'}`}
				alt='plume'
				width={isMobile ? 45 : 75}
				height={isMobile ? 44 : 73}
			/>
		</Center>
	);
};

export default Plume;
