import getIsServer from '@/utility/getIsServer';

const LEPITRE_DEV_URL = 'http://localhost:3000';
const LEPITRE_PROD_URL = 'https://lepitre.ch';

export const LEPITRE_URL =
  process.env.NODE_ENV === 'production' ? LEPITRE_PROD_URL : LEPITRE_DEV_URL;

const PAYLOAD_DEV_URL = 'http://localhost:3001';

export const PAYLOAD_REMOTE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://lepitre-admin.cloud.innovation-it.ch'
    : PAYLOAD_DEV_URL;

const PAYLOAD_LOCAL_URL =
  process.env.NODE_ENV === 'production'
    ? 'http://lepitre_admin:3001'
    : PAYLOAD_DEV_URL;

export const PAYLOAD_URL = getIsServer()
  ? PAYLOAD_LOCAL_URL
  : PAYLOAD_REMOTE_URL;

export const HCAPTCHA_SITE_KEY =
  process.env.NODE_ENV === 'development'
    ? '10000000-ffff-ffff-ffff-000000000001'
    : process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY;

export const MONGODB_URI =
  process.env.NODE_ENV === 'production'
    ? process.env.MONGODB_URI
    : process.env.MONGODB_URI;
